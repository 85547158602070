import { IUseBasis, IUseBasisProps } from 'models/composables/useBasis.model';
import BasesService from 'services/basesManager.service';
import { useUserStore } from 'store/user.store';

export function useBasis(props = {} as IUseBasisProps): IUseBasis {
  const basesManager = inject<BasesService>(BasesService.getServiceName());

  const userStore = useUserStore();

  const unAuthRegionFiasId = computed(
    () => props?.fiasId
      ? props.fiasId
      : (!userStore.isUserLoggedIn && userStore.selectedBase?.fiasId || basesManager?.cityFiasId.value) || undefined,
  );

  const authBasisId = computed(
    () => props.basisId
      ? props.basisId
      : (userStore.isUserLoggedIn && basesManager?.currentUserBase?.id) || undefined,
  );

  return {
    unAuthRegionFiasId,
    authBasisId,
  };
}
